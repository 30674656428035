import React from "react";
import { Navigate, useParams } from "react-router-dom";
import HomeNavbar from "../Homes/Components/HomeNavbar";
import HomeFooter from "../Homes/Components/HomeFooter";

const ClosedContainer = () => {
  const { label } = useParams();
  const unit = () => {
    if (label === "smp-9") {
      return {
        title: "SMP Muhammadiyah 9 Jakarta",
        bgunit: "bg-unit-smp9.jpg",
      };
    } else if (label === "smp-8") {
      return {
        title: "SMP Muhammadiyah 8 Jakarta",
        bgunit: "bg-unit-smp8.jpg",
      };
    } else if (label === "sd-5") {
      return { title: "SD Muhammadiyah 5 Jakarta", bgunit: "bg-unit-sd5.jpg" };
    } else {
      return <Navigate to={"/"} />;
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-between bg-white">
      <HomeNavbar />
      <div
        style={{ backgroundImage: `url(${unit().bgunit})` }}
        className="bg-slate-800 text-white flex min-h-screen px-10 bg-cover bg-center"
      >
        <div className="flex flex-col justify-center">
          <h1 className="lowercase text-4xl">
            "Mohon maaf pendaftaran {unit().title} belum dibuka, <br />
            silahkan kembali lagi nanti"
          </h1>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default ClosedContainer;
