import React, { Fragment } from "react";

const Select = (props) => {
  const { name, label, description = "", options = [], errors } = props;
  return (
    <Fragment>
      <label htmlFor={name} className="capitalize block mb-1 mt-4 font-bold">
        {label}
      </label>
      <span className="capitalize block mb-4 text-sm">{description}</span>
      <select {...props} className="p-2 w-full rounded capitalize bg-white">
        <option value="">--Pilih--</option>
        {options.map((option) => (
          <option key={option.toLowerCase()} value={option.toLowerCase()}>
            {option}
          </option>
        ))}
      </select>
      {errors[name] ? (
        <div className="w-full bg-red-500 text-white py-2 px-4 mt-3">
          {errors[name]}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Select;
