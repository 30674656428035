import React from "react";

const RegisterStep = ({ status }) => {
  return (
    <div className="bg-slate-50 flex gap-2 mb-6 rounded p-3 font-bold drop-shadow-lg">
      <div>
        <div className="flex gap-1">
          <span
            className={`${
              !status ? "bg-green-400" : "bg-gray-400"
            } w-7 h-7 rounded-full text-center text-white`}
          >
            1
          </span>
          <span className={`${!status ? "text-slate-800" : "text-gray-400 "}`}>
            Registrasi Akun
          </span>
        </div>
      </div>
      <div>
        <div className="flex gap-1">
          <span
            className={`${
              status ? "bg-green-400" : "bg-gray-400"
            } w-7 h-7 rounded-full text-center text-white`}
          >
            2
          </span>
          <span className={`${status ? "text-slate-800" : "text-gray-400"} `}>
            Informasi Akun
          </span>
        </div>
      </div>
    </div>
  );
};

export default RegisterStep;
