import React, { useState } from "react";
import RegisterAccountForm from "./RegisterAccountForm";
import RegisterMessageForm from "./RegisterMessageForm";
import RegisterMessageSuccess from "./RegisterMessageSuccess";
import RegisterStep from "./RegisterStep";
import RegisterSuccessContainer from "./RegisterSuccessContainer";

const RegisterAccountContainer = () => {
  const [success, setSuccess] = useState(false);
  return (
    <div className="py-8">
      <RegisterStep status={success} />
      {success ? <RegisterMessageSuccess /> : <RegisterMessageForm />}
      {success ? (
        <RegisterSuccessContainer />
      ) : (
        <RegisterAccountForm setSuccess={(data) => setSuccess(data)} />
      )}
    </div>
  );
};

export default RegisterAccountContainer;
