import React from "react";
import { Helmet } from "react-helmet-async";

const HomeSchema = () => {
  const JsonLd = {
    "@context": "http://schema.org/",
    "@type": "EducationalOrganization",
    name: "PPDB Limau Bendi School",
  };
  return (
    <Helmet>
      <title>PPDB Limau Bendi School</title>
      <link rel="canonical" href="http://mysite.com/example" />
      <script type="application/ld+json">{JSON.stringify(JsonLd)}</script>
    </Helmet>
  );
};

export default HomeSchema;
