import React from "react";

const RegisterMessageForm = () => {
  return (
    <div className="bg-[#000066] text-white p-3 rounded-xl">
      <h1>Registrasi Akun</h1>
      <ul className="list-disc ml-8 mb-4">
        <li>Pastikan anda telah membaca Semua aturan pendaftaran</li>
        <li>
          Akun digunakan untuk meberitahu jadwal ujian, tes online, pengumuman
          dan administrasi pendaftaran
        </li>
        <li>Pastikan email aktif dan password mudah diingat</li>
      </ul>
    </div>
  );
};

export default RegisterMessageForm;
