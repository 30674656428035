import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { assetsconfig, loginmodal } from "../Store";

const WaChat = () => {
  const assets = useRecoilValue(assetsconfig);
  const login = useRecoilValue(loginmodal);
  return (
    <Fragment>
      {!login ? (
        <div className="fixed bottom-[80px] right-1 w-52 cursor-pointer">
          <a
            href={`https://api.whatsapp.com/send/?phone=${assets?.humas_phone}&text&type=phone_number&app_absent=0`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/wa-chat.png" alt="wa-chat" />
          </a>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default WaChat;
