import React from "react";

const PageClosed = ({ content }) => {
  return (
    <div className="bg-slate-800 text-white text-center flex min-h-screen px-10">
      <div
        className="my-auto"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    </div>
  );
};

export default PageClosed;
