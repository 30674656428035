import React from "react";
import { Link, useParams } from "react-router-dom";
import RegisterAccountContainer from "./RegisterAccountContainer";
import TabInformation from "./TabInformation";
import TabProfile from "./TabProfile";

const TabContent = () => {
  const { unit, tab } = useParams();
  const activeTab = () => {
    if (tab === "profil") {
      return <TabProfile />;
    } else if (tab === "informasi") {
      return <TabInformation />;
    } else if (tab === "daftar") {
      return <RegisterAccountContainer />;
      // return <TabSma3RegisterForm />;
    } else {
      return <TabProfile />;
    }
  };
  return (
    <div className="py-14 px-3 lg:px-14">
      <div className="flex gap-1 text-center">
        <Link
          to={`/units/${unit}/profil`}
          className={`flex-1 p-4 cursor-pointer hover:bg-orange-500 text-white rounded-lg font-bold ${
            tab === "profil" ? "bg-green-500" : "bg-[#011736]"
          }`}
        >
          PROFIL SEKOLAH
        </Link>
        <Link
          to={`/units/${unit}/informasi`}
          className={`flex-1 p-4 cursor-pointer hover:bg-orange-500 text-white rounded-lg font-bold ${
            tab === "informasi" ? "bg-green-500" : "bg-[#011736]"
          }`}
        >
          INFORMASI
        </Link>
        <Link
          to={`/units/${unit}/daftar`}
          className={`flex-1 p-4 cursor-pointer hover:bg-orange-500 text-white rounded-lg font-bold ${
            tab === "daftar" ? "bg-green-500" : "bg-[#011736]"
          }`}
        >
          DAFTAR
        </Link>
      </div>
      <div className="px-1">
        <div className="w-full bg-[#B5E4FE] p-4 rounded-b-lg drop-shadow-lg">
          {activeTab()}
        </div>
      </div>
    </div>
  );
};

export default TabContent;
