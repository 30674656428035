import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import Textarea from "../../Components/Textarea";
import { loadingscreen } from "../../Store";
import HomeNavbar from "../Homes/Components/HomeNavbar";
import HomeFooter from "../Homes/Components/HomeFooter";

const ContactContainer = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [errors, setErrors] = useState([]);
  const [records, setRecords] = useState({});
  const handleInput = (el) => {
    const change = { ...records };
    change[el.target.name] = el.target.value;
    setRecords(change);
  };
  const sendMessage = (token) => {
    setLoading(true);
    records.g_recaptcha_response = token;
    axios
      .post(`/contacts/send`, records)
      .then(function (response) {
        // handle success
        setRecords({ name: "", phone: "", email: "", message: "" });
        setErrors([]);
        setLoading(false);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setErrors(error.response.data);
        setLoading(false);
        // console.log(error);
      });
    // });
  };
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`,
      function () {
        // console.log("Script loaded!");
      }
    );
  }, []);
  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_SITE_KEY, { action: "submit" })
        .then((token) => {
          sendMessage(token);
          // console.log(token);
          // setLoading(false);
        });
    });
  };

  return (
    <div className="min-h-screen flex flex-col justify-between bg-white">
      <HomeNavbar />
      <div className="flex flex-col p-8 ">
        <div className="flex justify-center">
          <h1 className="p-4 text-4xl text-slate-700 font-bold border-b-4 border-blue-600 w-1/2 text-center">
            CONTACT
          </h1>
        </div>
        <div className="bg-slate-200 p-4 rounded shadow-md mt-8">
          <div className="flex flex-col md:flex-row gap-8 justify-between">
            <div className="w-full md:w-1/3">
              <Input
                name="name"
                label="NAMA"
                value={records.name ?? ""}
                onChange={(el) => handleInput(el)}
                errors={errors}
              />
            </div>
            <div className="w-full md:w-1/3">
              <Input
                name="phone"
                label="TELP"
                value={records.phone ?? ""}
                onChange={(el) => handleInput(el)}
                errors={errors}
              />
            </div>
            <div className="w-full md:w-1/3">
              <Input
                name="email"
                label="E-MAIL"
                value={records.email ?? ""}
                onChange={(el) => handleInput(el)}
                errors={errors}
              />
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <Textarea
              name="message"
              label="PESAN"
              value={records.message ?? ""}
              onChange={(el) => handleInput(el)}
              errors={errors}
            />
            <div className="text-right mt-8">
              <Button onClick={(e) => handleOnClick(e)}>kirim pesan</Button>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default ContactContainer;
