import React from "react";
import Button from "../../Components/Button";

const RegisterSuccessContainer = () => {
  return (
    <div className="mt-16 bg-slate-50 rounded-xl p-4 w-full lg:w-1/2 mx-auto">
      <h1>Selamat akun telah tedaftar</h1>
      <p>Lakukan langkah ini untuk mengaktifkan akun anda !</p>
      <table>
        <tbody>
          <tr className="font-bold">
            <td>1.</td>
            <td>Verifikasi</td>
          </tr>
          <tr>
            <td></td>
            <td className="pb-6">
              Membuka email yang terdaftar dan verifikasi email dalam inbox
            </td>
          </tr>
          <tr className="font-bold">
            <td>2.</td>
            <td>Login</td>
          </tr>
          <tr>
            <td></td>
            <td>Setelah verifikasi email kalian dapat login ke laman akun</td>
          </tr>
        </tbody>
      </table>
      <div className="text-center mt-8 mb-8">
        <a
          href={process.env.REACT_APP_STUDENT_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button color="bg-green-500 rounded-xl w-1/2 drop-shadow-xl">
            login
          </Button>
        </a>
      </div>
    </div>
  );
};

export default RegisterSuccessContainer;
