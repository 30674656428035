import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Button from "../../Components/Button";
import { loadingscreen } from "../../Store";
import HomeNavbar from "../Homes/Components/HomeNavbar";
import HomeFooter from "../Homes/Components/HomeFooter";

const ArticleSingle = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const { slug } = useParams();
  const [page, setPage] = useState(false);
  const to_route = useNavigate();
  const getData = () => {
    setLoading(true);
    axios
      .get(`/articles/${slug}/show`)
      .then(function (response) {
        // handle success
        setPage(response.data);
        setLoading(false);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <div className="min-h-screen flex flex-col justify-between bg-white">
      <HomeNavbar />
      <div className="flex flex-col lg:p-8 mb-4">
        <div className="flex justify-center">
          <h1 className="w-full p-4 text-xl md:text-2xl lg:text-4xl text-slate-700 font-bold border-b-4 border-blue-600 mb-4 text-center">
            {page?.roman_number}
            {page.roman_number ? ". " : ""}
            {page?.title}
          </h1>
        </div>
        <div
          className="w-full p-4 text-lg text-justify"
          dangerouslySetInnerHTML={{
            __html: page?.content,
          }}
        ></div>
        <div className="mt-6 text-center">
          <Button
            color="bg-orange-400 rounded w-3/12"
            onClick={() => to_route(-1)}
          >
            back
          </Button>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default ArticleSingle;
