import React from "react";
import { Link } from "react-router-dom";

const ArticleItem = ({ title, slug, content, number }) => {
  return (
    <Link
      to={`${slug}`}
      className="mb-6 bg-slate-200 p-4 rounded shadow-md cursor-pointer hover:animate-pulse"
    >
      <div className="flex">
        <span className="rounded-full w-8 h-8 md:w-12 md:h-12 text-center md:pt-1 mb-5 text-lg md:text-3xl mr-3 bg-blue-600 text-white">
          {number}
        </span>
        <h2 className="py-2 pb-0 mb-5 text-md md:text-3xl">{title}</h2>
      </div>
      <div className="bg-blue-600 w-10/12 lg:w-3/12 h-1 mb-6"></div>
      <div
        className="md:pl-16 overflow-x-scroll sm:overflow-x-auto"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    </Link>
  );
};

export default ArticleItem;
