import React, { Fragment } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const MenuItems = () => {
  const [child, setChild] = useState(false);
  return (
    <Fragment>
      <Link className="hover:bg-slate-700" to={"/"}>
        Home
      </Link>
      <Link className="hover:bg-slate-700" to={"/cara-pembayaran"}>
        Cara Bayar
      </Link>
      <Link className="hover:bg-slate-700" to={"/contact"}>
        Contact
      </Link>
      <div
        className="hover:bg-slate-700 relative"
        onClick={() => setChild(!child)}
      >
        Daftar
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 35 35"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 absolute bottom-3 left-[75px]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
        <div
          className={`${
            child ? "flex" : "hidden"
          } flex-col w-full md:w-60 divide-y [&>*]:px-2 [&>*]:py-3 absolute top-16 right-0 bg-[#011736]`}
        >
          <Link to={"/units/sma-3/daftar"} className="hover:bg-slate-700">
            SMA Muhammadiyah 3 Jakarta
          </Link>
          <Link to={"/closed/smp-9"} className="hover:bg-slate-700">
            SMP Muhammadiyah 9 Jakarta
          </Link>
          <Link to={"/closed/smp-8"} className="hover:bg-slate-700">
            SMP Muhammadiyah 8 Jakarta
          </Link>
          <Link to={"/closed/sd-5"} className="hover:bg-slate-700">
            SD Muhammadiyah 5 Jakarta
          </Link>
        </div>
      </div>
      <a
        className="hover:bg-slate-700"
        href={process.env.REACT_APP_STUDENT_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Login
      </a>
    </Fragment>
  );
};

export default MenuItems;
