import { Route, Routes, useLocation } from "react-router-dom";
import LoadingScreen from "./Components/LoadingScreen";
import ContactContainer from "./Pages/Contacts/ContactContainer";
import HomeContainer from "./Pages/Homes/HomeContainer";
import AricleList from "./Pages/Articles/AricleList";
import ArticleSingle from "./Pages/Articles/ArticleSingle";
import InformationContainer from "./Pages/Informations/InformationContainer";
import TabContainer from "./Pages/Tabs/TabContainer";
import VerifyContainer from "./Pages/Verifies/VerifyContainer";
import ClosedContainer from "./Pages/Closed/ClosedContainer";
import { useEffect } from "react";
import WaChat from "./Components/WaChat";
import NotFound404 from "./Pages/Errors/NotFound404";
import LoginUnit from "./Pages/Homes/Components/LoginUnit";

function App() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
    // console.log(location);
  }, [location]);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        <Route
          path="/cara-pembayaran"
          element={<AricleList label={"cara-pembayaran"} />}
        />
        <Route path="/:label/:slug" element={<ArticleSingle />} />
        <Route path="/contact" element={<ContactContainer />} />
        <Route path="/informasi/:unit" element={<InformationContainer />} />
        <Route path="/informasi/:unit/:slug" element={<ArticleSingle />} />
        <Route path="/units/:unit/:tab" element={<TabContainer />} />
        <Route path="/:token/verify" element={<VerifyContainer />} />
        <Route path="/closed/:label" element={<ClosedContainer />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
      <LoginUnit />
      <WaChat />
      <LoadingScreen />
    </div>
  );
}

export default App;
