import React from "react";
import Pulse from "../../Components/Pulse";
import { InformationTitle } from "../../Utils/Title";
import ArticleItem from "../Articles/ArticleItem";

const InformationList = ({ items, unit, title = true }) => {
  return (
    <div className="flex flex-col md:p-8">
      {title ? (
        <div className="flex justify-center mb-8">
          <h1 className="p-4 text-4xl text-slate-700 font-bold border-b-4 border-blue-600 w-1/2 text-center">
            {InformationTitle(unit)}
          </h1>
        </div>
      ) : (
        ""
      )}
      <div className="flex flex-col p-3 md:p-8">
        {items.length > 0 ? (
          items.map((item, index) => (
            <ArticleItem
              key={item.id}
              title={item.title}
              slug={`/${item.label}/${unit}/${item.slug}`}
              content={item.content}
              number={item.roman_number}
            />
          ))
        ) : (
          <Pulse />
        )}
      </div>
    </div>
  );
};

export default InformationList;
