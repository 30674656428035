import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import { loadingscreen } from "../../Store";

const RegisterAccountForm = ({ setSuccess }) => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [records, setRecords] = useState({});
  const [errors, setErrors] = useState([]);
  const handleRecords = (el) => {
    const change = { ...records };
    change[el.target.name] = el.target.value;
    if (el.target.name === "password") {
      setErrors({ password_confirm: "" });
    }
    setRecords(change);
  };
  const handlePasswordConfirm = (el) => {
    const change = { ...records };
    change[el.target.name] = el.target.value;
    if (records.password === el.target.value) {
      setErrors({ password_confirm: "" });
    } else {
      setErrors({ password_confirm: "Password tidak cocok" });
    }
    setRecords(change);
  };
  const register = (token) => {
    records.g_recaptcha_response = token;
    // console.log(records);
    axios
      .post(`/register/account`, records)
      .then(function (response) {
        // handle success
        setLoading(false);
        setSuccess(true);
        setErrors([]);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        setErrors(error.response.data);
        // console.log(error.message);
      });
  };
  const handleRecordsOnlyNumber = (el) => {
    // console.log(typeof recordsRef.current.nisn);
    const change = { ...records };
    const input = el.target;
    const value = input.value;
    let thenum = value.replace(/\D/g, "");
    change[input.name] = thenum;
    setRecords(change);
    // console.log(recordsRef.current);
  };
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`,
      function () {
        // console.log("Script loaded!");
      }
    );
  }, []);
  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_SITE_KEY, { action: "submit" })
        .then((token) => {
          register(token);
          // console.log(token);
          // setLoading(false);
        });
    });
  };
  return (
    <div>
      {errors.message ? (
        <div className="bg-red-300 mt-6 rounded p-5 font-bold uppercase">
          {errors.message}
        </div>
      ) : (
        ""
      )}
      <Input
        name="name"
        type="text"
        label="A. Nama Lengkap Peserta Didik"
        description="nama sesuai Dokumen resmi yang berlaku (Akta lahir, KK, atau Ijazah sebelumnya)"
        value={records.name ?? ""}
        errors={errors}
        onChange={(e) => handleRecords(e)}
      />
      <Input
        name="phone"
        type="tel"
        label="B. Nomor Whatsapp Orang Tua"
        description="Wajib diisi untuk pemberitahuan"
        value={records.phone ?? ""}
        errors={errors}
        onChange={(e) => handleRecordsOnlyNumber(e)}
      />
      <Input
        name="email"
        type="email"
        label="C. E-Mail siswa"
        description="Pastikan menggunakan e-mail aktif"
        value={records.email ?? ""}
        errors={errors}
        onChange={(e) => handleRecords(e)}
      />
      <Input
        name="password"
        type="password"
        label="D. Password"
        value={records.password ?? ""}
        errors={errors}
        onChange={(e) => handleRecords(e)}
      />
      <Input
        name="password_confirm"
        type="password"
        label="E. Ulangi Password"
        value={records.password_confirm ?? ""}
        errors={errors}
        onChange={(e) => handlePasswordConfirm(e)}
      />
      <Select
        name="knowing"
        type="text"
        label="F. Mengetahui SMA Muhammadiyah 3 Jakarta dari"
        value={records.knowing ?? ""}
        options={[
          "Sosial media (instagram, facebook, youtube)",
          "Internet",
          "Teman",
          "Keluarga/sodara",
          "Sekolah SMP",
        ]}
        onChange={(e) => handleRecords(e)}
        errors={errors}
      />
      <div className="flex flex-col mt-12">
        <Button
          color="bg-[#000066] rounded-full"
          onClick={(e) => handleOnClick(e)}
        >
          daftar
        </Button>
      </div>
    </div>
  );
};

export default RegisterAccountForm;
