import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import PageClosed from "../../Components/PageClosed";
import { loadingscreen } from "../../Store";
import TabContent from "./TabContent";
import HomeNavbar from "../Homes/Components/HomeNavbar";
import HomeFooter from "../Homes/Components/HomeFooter";

const TabContainer = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [open, setOpen] = useState({ status: false });
  const getStatus = () => {
    setLoading(true);
    axios
      .get(`/registrations/status`)
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          if (response.data.status === false) {
            setOpen(response.data);
          } else {
            setOpen({ status: true });
          }
        }
        setLoading(false);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        // console.log(error.message);
      });
  };
  useEffect(() => {
    getStatus();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-between bg-white">
      <HomeNavbar />
      {open.status ? (
        <TabContent />
      ) : (
        <PageClosed content={open.content ?? ""} />
      )}
      <HomeFooter />
    </div>
  );
};

export default TabContainer;
