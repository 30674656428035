import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import MenuItems from "../../../Components/MenuItems";
import { assetsconfig, loadingscreen } from "../../../Store";

const HomeNavbar = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [assets, setAssets] = useRecoilState(assetsconfig);
  const [mobile, setMobile] = useState(false);
  const getAsset = () => {
    setLoading(true);
    axios
      .get(`/templates`)
      .then(function (response) {
        // handle success
        setAssets(response.data);
        setLoading(false);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        // console.log(error.message);
      });
  };
  useEffect(() => {
    if (assets === false) getAsset();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <nav className="bg-[#011736] z-10">
      <div className="flex justify-between items-center px-2">
        <div className="flex items-center gap-2 text-white">
          <img
            src={assets.homepage?.logo ?? ""}
            width={49}
            height={51}
            alt="Limau Bendi School Logo"
          />
          <span className="w-1/2 text-sm">
            COMMITTED TO EXCELLENCE IN ISLAMIC EDUCATION
          </span>
        </div>
        <div className="hidden lg:flex gap-2 text-white [&>*]:px-6 [&>*]:py-5 cursor-pointer transition duration-300">
          <MenuItems />
        </div>
        <div className="lg:hidden flex text-slate-300 justify-end">
          <button
            className="cursor-pointer border border-slate-300 rounded"
            onClick={() => setMobile(!mobile)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          </button>
        </div>
      </div>
      {mobile ? (
        <div className="lg:hidden flex flex-col divide-y text-slate-300 cursor-pointer animate__animated animate__slideInDown [&>*]:py-3">
          <MenuItems />
        </div>
      ) : (
        ""
      )}
    </nav>
  );
};

export default HomeNavbar;
