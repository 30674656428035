import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { loadingscreen } from "../Store";

const LoadingScreen = () => {
  const loading = useRecoilValue(loadingscreen);
  return (
    <Fragment>
      {loading ? (
        <div className="bg-black opacity-90 p-5 flex space-x-3 justify-center items-center fixed top-0 w-full min-h-screen">
          <div className="w-10 h-10 bg-red-500 rounded-full animate-bounce"></div>
          <div className="w-10 h-10 bg-green-500 rounded-full animate-bounce"></div>
          <div className="w-10 h-10 bg-blue-500 rounded-full animate-bounce"></div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default LoadingScreen;
