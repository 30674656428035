import React from "react";
import { useRecoilValue } from "recoil";
import { assetsconfig } from "../../../Store";

const HomeFooter = () => {
  const assets = useRecoilValue(assetsconfig);
  return (
    <div className="bg-[#010223] text-white text-center p-10 flex flex-wrap gap-10 xl:gap-44 justify-center">
      <div>
        <h3 className="font-bold text-3xl">PPDB LBS</h3>
        <img
          className="mx-auto mt-8"
          src={assets.homepage?.logo}
          alt="Limau Bendi School Logo"
        />
      </div>
      <div>
        <h3 className="font-bold text-3xl">Address</h3>
        <p className="mt-6 text-slate-400">
          Jl. Limau II BLOK No.3,
          <br /> RT.3/RW.3, Kramat Pela,
          <br /> Kec. Kby. Baru,
          <br /> Kota Jakarta Selatan, Jakarta 12130
        </p>
      </div>
      <div>
        <h3 className="font-bold text-3xl">About</h3>
        <div className="divide-y divide-none mt-6 text-slate-400">
          <div className="py-1">SMA Muhammadiyah 3</div>
          <div className="py-1">SMP Muhammadiyah 8</div>
          <div className="py-1">SMP Muhammadiyah 9</div>
          <div className="py-1">SD Muhammadiyah 5</div>
        </div>
      </div>
      <div>
        <h3 className="font-bold text-3xl">Contact</h3>
        <div className="divide-y divide-none mt-6 text-slate-400">
          <div className="py-1">Humas SMA Muh 3</div>
          <div className="py-1">Humas SMP Muh 8</div>
          <div className="py-1">Humas SMP Muh 9</div>
          <div className="py-1">Humas SD Muh 5</div>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
