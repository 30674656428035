import React from "react";
import Banner from "../../Components/Banner";
import HomeFooter from "./Components/HomeFooter";
import HomeNavbar from "./Components/HomeNavbar";
import HomeDescription from "./Components/HomeDescription";
import HomeRegisterStep from "./Components/HomeRegisterStep";
import HomeSchema from "./Components/HomeSchema";
import SectionLbs from "./Components/SectionLbs";
import SectionRegister from "./Components/SectionRegister";
import CopyRight from "./Components/CopyRight";
import { useRecoilValue } from "recoil";
import { assetsconfig } from "../../Store";

const HomeContainer = () => {
  const assets = useRecoilValue(assetsconfig);

  return (
    <div className="min-h-screen bg-white">
      <HomeSchema />
      <HomeNavbar />
      <Banner image={assets.homepage?.banner} />
      {/* <LoginSection /> */}
      <SectionLbs
        background={assets.homepage?.hero_bg}
        hero={assets.homepage?.hero}
        brochure={assets.homepage?.brochure ?? "#"}
      />
      <SectionRegister
        background={assets.homepage?.unit_bg}
        smp9_bg={assets.homepage?.smp9_bg}
        smp8_bg={assets.homepage?.smp8_bg}
        sd5_bg={assets.homepage?.sd5_bg}
        sma3_bg={assets.homepage?.sma3_bg}
        smp9_logo={assets.homepage?.smp9_logo}
        smp8_logo={assets.homepage?.smp8_logo}
        sd5_logo={assets.homepage?.sd5_logo}
        sma3_logo={assets.homepage?.sma3_logo}
      />
      <HomeRegisterStep image={assets.homepage?.registration_step} />
      <HomeDescription
        background={assets.homepage?.desc_bg}
        image={assets.homepage?.desc_image}
      />
      <HomeFooter />
      <CopyRight />
    </div>
  );
};

export default HomeContainer;
