import React from "react";

const HomeDescription = ({ background, image }) => {
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className="block py-32 bg-cover bg-center"
    >
      <div className="block relative mb-32 border-t-4 border-[#000066]">
        <h2 className="w-10/12 md:w-1/2 text-center text-2xl font-bold text-white py-4 rounded-full bg-[#000066] section-title px-10">
          Come Join Us
        </h2>
      </div>
      <div className="p-3 md:pl-32 md:pr-20">
        <div className="bg-[#000066] flex flex-col-reverse md:flex-row justify-center gap-8 md:gap-0 pt-20 md:pt-10 rounded-3xl text-white">
          <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/4 relative">
            <img
              className="w-1/3 mx-auto md:w-full lg:w-8/12 xl:w-10/12 md:absolute md:left-[-50px] xl:left-[-120px] 2xl:left-[-150px] bottom-[-1px]"
              src={image}
              alt="Commited"
            />
          </div>
          <div className="w-full xl:w-4/6 pt-0 p-10 2xl:py-24">
            <h2 className="text-xl lg:text-3xl xl:text-4xl 2xl:text-6xl">
              COMMITTED TO <br />
              EXCELLENCE IN ISLAMIC <br />
              EDUCATION
            </h2>
            <p className="mt-8 w-full text-md lg:text-lg xl:text-3xl 2xl:text-4xl">
              Sekolah Muhammadiyah adalah sekolah yang dibangun dengan misi
              dakwah dan mengajarkan akhlak yang mulia.
            </p>
            <p className="mt-8 2xl:mt-14 w-full text-md lg:text-lg xl:text-3xl 2xl:text-4xl">
              “Mendidik anak menjadi cerdas itu bagus tetapi harus dilandaskan
              pada akidah dan akhlak yang Islami,”
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-28">
        <a
          href="#unit"
          rel="noreferrer"
          className="w-10/12 md:w-1/2 text-xl md:text-2xl text-center font-bold text-white py-4 rounded-full bg-green-500 px-10 border-4 border-white shadow-md hover:bg-[#000066] transition duration-300"
        >
          Daftar Sekarang
        </a>
      </div>
    </div>
  );
};

export default HomeDescription;
