import React from "react";

const VeirfySuccess = () => {
  return (
    <div className="bg-green-400 p-8 rounded font-bold">
      E-Mail berhasil diverifikasi, anda dapat login ke dashboard siswa
    </div>
  );
};

export default VeirfySuccess;
