export const InformationTitle = (unit) => {
  let title = "";
  if (unit === "sd-5") {
    title = "INFORMASI SD MUHAMMADIYAH 5";
  } else if (unit === "smp-9") {
    title = "INFORMASI SMP MUHAMMADIYAH 9";
  } else if (unit === "smp-8") {
    title = "INFORMASI SMP MUHAMMADIYAH 8";
  } else {
    title = "INFORMASI SMA MUHAMMADIYAH 3";
  }
  return title;
};
