import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { loadingscreen } from "../../Store";

import ArticleItem from "./ArticleItem";
import HomeNavbar from "../Homes/Components/HomeNavbar";
import HomeFooter from "../Homes/Components/HomeFooter";

const AricleList = ({ label }) => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [items, setItems] = useState([]);
  const getList = () => {
    setLoading(true);
    axios
      .get(`/articles/${label}`)
      .then(function (response) {
        // handle success
        setItems(response.data);
        setLoading(false);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        // console.log(error.message);
      });
  };

  useEffect(() => {
    getList();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-between bg-white">
      <HomeNavbar />
      <div className="w-full mt-2">
        <div className="flex justify-center">
          <h1 className="w-10/12 lg:w-1/2 p-2 lg:p-4 text-4xl text-slate-700 font-bold border-b-4 border-blue-600 text-center">
            CARA BAYAR
          </h1>
        </div>
        <div className="flex flex-col p-2 lg:p-8 mt-4">
          {items.map((item) => (
            <ArticleItem
              key={item.id}
              title={item.title}
              slug={item.slug}
              description={item.description}
              label={item.label}
            />
          ))}
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default AricleList;
