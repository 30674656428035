import React from "react";

const HomeRegisterStep = ({ image }) => {
  return (
    <div className="block">
      <div className="block p-10 bg-[#000066]">
        <img
          className="w-full md:w-3/5 mx-auto"
          src={image}
          alt="Alur Pendaftaran"
        />
      </div>
    </div>
  );
};

export default HomeRegisterStep;
