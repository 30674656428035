import React from "react";
import HomeNavbar from "../Homes/Components/HomeNavbar";
import HomeFooter from "../Homes/Components/HomeFooter";

const NotFound404 = () => {
  return (
    <div className="min-h-screen flex flex-col justify-between bg-slate-800">
      <HomeNavbar />
      <div className="flex flex-col p-8 ">
        <div className="flex justify-center">
          <h1 className="p-4 text-4xl text-slate-100 font-bold text-center">
            404 NOT FOUND
          </h1>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default NotFound404;
