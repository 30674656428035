import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import Pulse from "../../Components/Pulse";
import { loadingscreen } from "../../Store";

const TabProfile = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [page, setPage] = useState(false);
  const getData = () => {
    setLoading(true);
    axios
      .get(`/units/profile`)
      .then(function (response) {
        // handle success
        setPage(response.data);
        setLoading(false);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        // console.log(error);
      });
  };

  useEffect(() => {
    getData();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      {page ? (
        <div
          dangerouslySetInnerHTML={{
            __html: page,
          }}
        ></div>
      ) : (
        <Pulse />
      )}
    </Fragment>
  );
};

export default TabProfile;
