import React, { Fragment } from "react";
import { useRecoilState } from "recoil";
import { loginmodal } from "../../../Store";

const LoginUnit = () => {
  const [login, setLogin] = useRecoilState(loginmodal);
  return (
    <Fragment>
      {login ? (
        <div className="bg-black/80 p-5 flex space-x-3 justify-center items-center fixed top-0 w-full min-h-screen">
          <div className="relative min-h-screen px-4 md:flex md:items-center md:justify-center">
            <div className="fixed inset-x-0 bottom-[-15px] z-50 mx-4 mb-4 h-1/2 rounded-t-lg bg-white p-4 animate__animated animate__fadeInUp">
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <h2 className="text-3xl font-bold">Login</h2>
                  <button
                    className="bg-red-600 text-white px-3 rounded"
                    onClick={() => setLogin(!login)}
                  >
                    X
                  </button>
                </div>
                <a
                  href={process.env.REACT_APP_STUDENT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="mt-6 cursor-pointer rounded-md border border-gray-400 p-4 hover:bg-indigo-800 hover:text-white">
                    SMA Muhammadiyah 3 Jakarta
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default LoginUnit;
