import React from "react";
import HomeUnit from "./HomeUnit";

const SectionRegister = ({
  background,
  smp9_bg,
  smp8_bg,
  sd5_bg,
  sma3_bg,
  smp9_logo,
  smp8_logo,
  sd5_logo,
  sma3_logo,
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className="block py-32 pb-40 bg-cover bg-center border-t-4 border-[#000066] relative"
    >
      <h2 className="w-10/12 md:w-1/2 text-center text-2xl font-bold text-white px-8 py-4 rounded-full bg-[#000066] section-title">
        Pendaftaran Siswa
      </h2>
      <div
        id="unit"
        className="flex flex-wrap justify-center mt-20 gap-10 unit-container"
      >
        <HomeUnit
          unitImage={smp9_bg}
          logoImage={smp9_logo}
          title="SMP Muhammadiyah 9<br/>Jakarta"
          bottom={198}
          route="/closed/smp-9"
          register="/closed/smp-9"
        />
        <HomeUnit
          unitImage={smp8_bg}
          logoImage={smp8_logo}
          title="SMP Muhammadiyah 8<br/>Jakarta"
          bottom={209}
          route="/closed/smp-8"
          register="/closed/smp-8"
        />
        <HomeUnit
          unitImage={sd5_bg}
          logoImage={sd5_logo}
          title="SD Muhammadiyah 5<br/>Jakarta"
          bottom={209}
          route="/closed/sd-5"
          register="/closed/sd-5"
        />
        <HomeUnit
          unitImage={sma3_bg}
          logoImage={sma3_logo}
          title="SMA Muhammadiyah 3<br/>Jakarta"
          bottom={209}
          route="units/sma-3/informasi"
          register="units/sma-3/daftar"
        />
      </div>
      <div className="block relative mt-32 border-t-4 border-[#000066]">
        <h2 className="w-10/12 md:w-1/2 text-center text-2xl font-bold text-white px-10 py-4 rounded-full bg-[#000066] section-title">
          Alur Pendaftaran
        </h2>
      </div>
    </div>
  );
};

export default SectionRegister;
