import React from "react";

const CopyRight = () => {
  return (
    <div className="bg-[#010223] text-white p-10 flex justify-center">
      <div>
        <h4>Copyright © TIF {new Date().getFullYear()}</h4>
      </div>
    </div>
  );
};

export default CopyRight;
