import React from "react";

const VerifyFail = () => {
  return (
    <div className="bg-red-300 p-8 rounded font-bold">
      E-Mail gagal diverifikasi, silahkan hubungi admin melalui halaman Contact
    </div>
  );
};

export default VerifyFail;
