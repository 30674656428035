import React from "react";
import { useSetRecoilState } from "recoil";
import { loginmodal } from "../../../Store";

const SectionLbs = ({ background, hero, brochure }) => {
  const setLogin = useSetRecoilState(loginmodal);
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className={`flex flex-col lg:flex-row gap-3 py-8 pb-20 lg:py-32`}
    >
      <div className="md:p-8 w-full">
        <img className="w-full" src={hero} alt="Hero" />
      </div>
      <div className="p-8 w-full">
        <h1 className="text-6xl md:text-4xl 2xl:text-8xl block">PPDB LBS</h1>
        <div className="w-1/4 bg-black h-[2px] mt-4"></div>
        <p className="mt-6 leading-10 text-2xl md:text-xl 2xl:text-4xl">
          Adalah sistem seleksi penerimaan siswa baru pada sekolah-sekolah di
          lingkungan Limau Bendi yang terdiri dari{" "}
          <strong>SD Muh 5, SMP Muh 8, SMP Muh 9 dan SMA Muh 3 Jakarta</strong>
        </p>
        <p className="mt-8 2xl:mt-28 text-2xl md:text-xl 2xl:text-4xl">
          Proses pendaftaran calon peserta Limau Bendi School (LBS) dan{" "}
          <strong>ujiannya dilakukan secara online (daring).</strong>
        </p>
        <div className="flex flex-col">
          <button
            className="w-full text-lg text-center font-bold text-white py-4 rounded-full bg-indigo-800 px-10 border-4 border-white shadow-md hover:bg-[#000066] transition duration-300 float-right mt-8"
            onClick={() => setLogin(true)}
          >
            Login
          </button>
          <a
            href={brochure}
            target="_blank"
            rel="noreferrer"
            className="text-lg text-center font-bold text-white py-3 rounded-full bg-green-500 px-10 border-4 border-white shadow-md hover:bg-[#000066] transition duration-300 mt-6 block"
          >
            Download Brosur Dan Tata Cara Pendaftaran
          </a>
        </div>
      </div>
    </div>
  );
};

export default SectionLbs;
