import React from "react";

const RegisterMessageSuccess = () => {
  return (
    <div className="bg-[#000066] text-white p-3 rounded-xl">
      <h1>Informasi Akun</h1>
      <ul className="list-disc ml-8 mb-4">
        <li>Login dapat dilakukan jika telah melakukan verifikasi E-Mail</li>
        <li>Pastikan kalian mengingat email dan password akun</li>
      </ul>
    </div>
  );
};

export default RegisterMessageSuccess;
