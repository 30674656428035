import React from "react";
import { Link } from "react-router-dom";

const HomeUnit = ({ unitImage, logoImage, title, bottom, route, register }) => {
  return (
    <div className="w-2/5 md:w-2/12">
      <div className="shadow-xl rounded-t-full rounded-b-full">
        <div>
          <div className="flex items-end bg-white rounded-t-full">
            <img
              className="w-full"
              src={unitImage}
              alt={title.replace("<br/>", " ")}
            />
          </div>
          <div className="bg-white flex flex-col items-center h-72 rounded-b-full relative">
            <img
              className={`absolute`}
              style={{ bottom: `${bottom}px` }}
              src={logoImage}
              alt={title.replace("<br/>", " ")}
            />
            <Link
              to={route}
              className="bg-pink-600 text-white px-10 py-4 rounded-full font-bold shadow-xl border-white border-4 absolute bottom-32 transition duration-300 hover:bg-green-500"
            >
              Informasi
            </Link>
            <Link
              to={register}
              className="bg-[#000066] text-white px-10 py-4 rounded-full font-bold shadow-xl border-white border-4 absolute bottom-11 transition duration-300 hover:bg-green-500"
            >
              Daftar
            </Link>
          </div>
        </div>
      </div>
      <div
        className="mt-8 text-center font-bold text-lg text-green-400 md:text-black"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      ></div>
    </div>
  );
};

export default HomeUnit;
