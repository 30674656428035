import React, { Fragment } from "react";

const Textarea = (props) => {
  const { name, label, description = "", errors } = props;
  return (
    <Fragment>
      <label htmlFor={name} className="capitalize block mb-1 mt-4 font-bold">
        {label}
      </label>
      <span className="capitalize block mb-4 text-sm">{description}</span>
      <textarea
        {...props}
        className="p-2 w-full rounded bg-white"
        placeholder={label}
      ></textarea>
      {errors[name] ? (
        <div className="w-full bg-red-500 text-white py-2 px-4 mt-3">
          {errors[name]}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Textarea;
