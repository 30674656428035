import { atom } from "recoil";
export const assetsconfig = atom({
  key: "assets",
  default: false,
});
export const loginmodal = atom({
  key: "login",
  default: false,
});
export const loadingscreen = atom({
  key: "loading",
  default: false,
});
