import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { loadingscreen } from "../../Store";
import InformationList from "../Informations/InformationList";

const TabInformation = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [items, setItems] = useState([]);
  const { unit } = useParams();
  const getList = () => {
    setLoading(true);
    axios
      .get(`/articles/informasi`)
      .then(function (response) {
        // handle success
        setItems(response.data);
        setLoading(false);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        // console.log(error.message);
      });
  };
  useEffect(() => {
    getList();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return <InformationList items={items} unit={unit} title={false} />;
};

export default TabInformation;
